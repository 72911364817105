import React from "react";
import Grid from "./grid";
import Button from "./button";

export default function BlockPartners() {
  return (
    <div className="block-partners">
      <Grid
        container
        maxWidth="lg"
        columnSpacing={{
          xs: 2,
        }}
        rowSpacing={{
          md: 8,
          xs: 0,
        }}
      >
        <Grid
          xs={12}
          sx={{
            mt: {
              md: 14,
              xs: 8,
            },
          }}
        >
          <h2 className="block-title">Nos partenaires de confiance</h2>
          <div className="cards-wrapper">
            <div className="card-partner">
              <div className="image-side">
                <img src="/images/partner-1.png" alt="partner" />
              </div>
              <div className="text-side">
                <div className="title">
                  <h3>Transaction sécurisée</h3>
                </div>
                <div className="card-body">
                  <p>
                    Service de coffre-fort avec déblocage des fonds une fois la
                    transaction réalisée.
                  </p>
                </div>
                <div className="actions">
                  <Button text="Découvrir leur service" url="https://www.cashsentinel.com/fr/" outlined />
                </div>
              </div>
            </div>
            <div className="card-partner">
              <div className="text-side">
                <div className="title">
                  <h3>Services indispensables</h3>
                </div>
                <div className="card-body">
                  <p>
                    Véritable conciergerie automobile, Click & Fi met à votre
                    disposition son expertise du financement automobile et des
                    prestations complémentaires (Entretien / extension de
                    garantie) pour vous permettre d’atteindre plus d’acheteurs.
                  </p>
                </div>
                <div className="actions">
                  <Button
                    text="Découvrir leur service"
                    outlined
                    url="https://click-and-fi.lagentx.tech/"
                  />
                </div>
              </div>
              <div className="image-side">
                <img src="/images/partner-2.png" alt="partner" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
