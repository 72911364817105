import React, { useEffect, useState } from "react";
import Grid from "./grid";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Slider from "@mui/material/Slider";
import Button from "./button";
import { sliderLabelText, isDev } from "../shared/helpers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "../shared/axiosInstance";
import setAccessToekn from "../shared/setAccessToken";
import { AxiosError } from "axios";

const schema = yup.object().shape({
  marque: yup.object().required("Ce champ est obligatoire"),
  prix: yup
    .mixed()
    .test("Doit être un nombre", "Doit être un nombre", function (value) {
      if (value === "") {
        return true; // Empty string is valid
      }
      return yup.number().isValidSync(value); // Validate as number
    }),
});

const schemaSell = yup.object().shape({
  marque: yup.string().required("Ce champ est obligatoire"),
  model: yup.string().required("Ce champ est obligatoire"),
});

const options = [
  { value: "50000", label: "50 000" },
  { value: "100000", label: "100 000" },
  { value: "150000", label: "150 000" },
  { value: "200000", label: "200 000" },
  { value: "250000", label: "250 000" },
  { value: "300000", label: "300 000" },
];

const objValue = (value: string) => {
  return { value, label: value };
};

type ObjValue = {
  value: string;
  label: string;
};

export default function Intro() {
  const navigate = useNavigate();

  const [activeForm, setActiveForm] = useState("buy");
  const [marqueOptions, setMarquesOptions] = useState<Array<ObjValue | any>>(
    []
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(activeForm === "buy" ? schema : schemaSell),
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let searchQuery = `SELECT+Marque__c+FROM+Vehicule__c`;

        !isDev ? (searchQuery += "+WHERE+Website_uploading__c+=+true") : "";

        const { data } = await axiosInstance.get(
          `/services/data/v56.0/query?q=${searchQuery}`
        );

        let recordsMarques: Array<ObjValue | string> = [];

        data.records.forEach((car: { Marque__c: string }) => {
          // marques
          if (!recordsMarques.includes(car.Marque__c)) {
            recordsMarques.push(car.Marque__c);
          }
        });
        recordsMarques = recordsMarques.map((v: any) => objValue(v));
        setMarquesOptions(recordsMarques);
      } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToekn();
        }
      }
    };
    fetchProducts();
  }, []);

  const onSubmit = (data: any) => {
    navigate("/acheter", { state: { formData: data } });
  };
  const onSubmitSell = (data: any) => {
    navigate("/vendre", { state: { formData: data } });
  };
  return (
    <div className="intro">
      <Grid container maxWidth="lg">
        <Grid
          xs={12}
          sx={{
            px: {
              sm: 2,
              md: 2,
            },
          }}
        >
          <div className="wrapper">
            <div className="forms-toggler">
              <div className="form-navigator">
                <ul>
                  <li onClick={() => setActiveForm("buy")}>Acheter</li>
                  <li onClick={() => setActiveForm("sell")}>Vendre</li>
                </ul>
              </div>
              <div className="forms-wrapper">
                <div
                  className={`form-content-wrapper form-content-wrapper-${activeForm}`}
                >
                  <div className="form-wrapper">
                    {activeForm === "buy" && (
                      <form className="form form-buy" id="form-buy">
                        <div className="form-controllers">
                          <div className="form-control-wrapper">
                            <label className="form-label" htmlFor="marque">
                              Marque
                            </label>

                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Select
                                  {...register("marque")}
                                  className="form-control form-control-select"
                                  classNamePrefix="react-select"
                                  options={marqueOptions}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Choisissez la marque"
                                  id="marque"
                                />
                              )}
                              name="marque"
                            />

                            {errors["marque"]?.message && (
                              <p className="error">
                                {errors["marque"] &&
                                  (errors["marque"] as { message: string })
                                    .message}
                              </p>
                            )}
                          </div>
                          <div className="form-control-wrapper">
                            <label className="form-label" htmlFor="miles">
                              Kilométrage max
                            </label>

                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <Select
                                  {...register("miles")}
                                  className="form-control form-control-select"
                                  classNamePrefix="react-select"
                                  options={options}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Choisissez vos&nbsp;km"
                                  id="miles"
                                />
                              )}
                              name="miles"
                            />
                            {errors["miles"]?.message && (
                              <p className="error">
                                {errors["miles"] &&
                                  (errors["miles"] as { message: string })
                                    .message}
                              </p>
                            )}
                          </div>
                          <div className="form-control-wrapper">
                            <label className="form-label" htmlFor="miles">
                              Budget mensuel
                            </label>

                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              defaultValue={[0, 1000]}
                              name="monthly"
                              render={({ field: { onChange, value, ref } }) => (
                                <div
                                  className="form-control form-control-slider"
                                  id="monthly"
                                  ref={ref}
                                >
                                  <Slider
                                    // {...register("monthly")}
                                    getAriaLabel={() => "range-slider"}
                                    min={0}
                                    max={1000}
                                    step={100}
                                    value={value}
                                    onChange={(e, newValue) => {
                                      onChange(e);
                                      setValue("monthly", newValue, {
                                        shouldValidate: true,
                                      });
                                    }}
                                    size="small"
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(v) =>
                                      sliderLabelText(v, "€")
                                    }
                                  />
                                </div>
                              )}
                            />

                            {errors["monthly"]?.message && (
                              <p className="error">
                                {errors["monthly"] &&
                                  (errors["monthly"] as { message: string })
                                    .message}
                              </p>
                            )}
                          </div>
                          <div className="form-actions">
                            <Button
                              icon="search"
                              dark
                              onPress={handleSubmit(onSubmit)}
                            />
                          </div>
                        </div>
                      </form>
                    )}
                    {activeForm === "sell" && (
                      <form className="form form-sell" id="form-sell">
                        <div className="form-controllers">
                          <div className="form-control-wrapper">
                            <label className="form-label" htmlFor="marque">
                              Marque
                            </label>

                            <input
                              id="marque"
                              className="form-control"
                              placeholder="Marque de votre véhicule."
                              {...register("marque")}
                            />

                            {errors["marque"]?.message && (
                              <p className="error">
                                {errors["marque"] &&
                                  (
                                    errors["marque"] as {
                                      message: string;
                                    }
                                  ).message}
                              </p>
                            )}
                          </div>
                          <div className="form-control-wrapper">
                            <label className="form-label" htmlFor="model">
                              Modèle
                            </label>
                            <input
                              id="model"
                              className="form-control"
                              placeholder="modèle de votre véhicule."
                              {...register("model")}
                            />
                            {errors["model"]?.message && (
                              <p className="error">
                                {errors["model"] &&
                                  (
                                    errors["model"] as {
                                      message: string;
                                    }
                                  ).message}
                              </p>
                            )}
                          </div>
                          <div className="form-actions">
                            <Button
                              icon="search"
                              dark
                              onPress={handleSubmit(onSubmitSell)}
                            />
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                  <div className="form-content">
                    {activeForm === "buy" && (
                      <Button
                        outlined
                        text="Acheter un véhicule"
                        to="/acheter"
                      />
                    )}
                    {activeForm === "sell" && (
                      <>
                        <Button
                          outlined
                          text="Vendez un véhicule"
                          to="/vendre"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
