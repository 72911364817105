import axiosInstance from "./axiosInstance";
import setAccessToekn from "./setAccessToken";
import { AxiosError } from "axios";

const fetchImages = async (cardId: string) => {
    try {
        const apiCall = await axiosInstance.get(
            `/services/data/v56.0/query?q=SELECT+CheminAbsolu__c,Ordre__c,Type__c,Vehicule__c +FROM+Media__c+WHERE+Vehicule__c+=+'${cardId}'+order+by+ordre__c+ASC`
        );

        // const images = apiCall.data.records
        //     .map((c) => `https://image-compressor-api.lagentx.tech/xet?url=${encodeURIComponent(c.CheminAbsolu__c)}&quality=${process.env.REACT_APP_SLIDESHOW_QUALITY}&slash=${process.env.REACT_APP_SLIDESHOW_SLASH}&cat=${process.env.REACT_APP_SLIDESHOW_CAT}`);

        const images = apiCall.data.records
            .map((c) => `${c.CheminAbsolu__c}`);


        return images;
    }
    catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
        }
    }
};


const fetchThumbnails = async (cardId: string) => {
    console.log("i got called")
    try {
        const images:any = []

        // Transformation des IDs en format SOQL
        const soqlIds = cardId.split(',').map(id => `'${id}'`).join(',');
        const apiCallThumbnail = await axiosInstance.get(
            `/services/data/v56.0/query?q=SELECT+CheminAbsolu__c,Ordre__c,Type__c,Vehicule__c +FROM+Media__c+WHERE+Vehicule__c+In+(${soqlIds})+AND+Ordre__c+=+0`
        );

        apiCallThumbnail.data.records.forEach((e)=>{
            const id = e.Vehicule__c
            // const imageUrl = `https://image-compressor-api.lagentx.tech/xet?url=${encodeURIComponent(e.CheminAbsolu__c)}&quality=${process.env.REACT_APP_THUMBNAIL_QUALITY}&slash=${process.env.REACT_APP_THUMBNAIL_SLASH}&cat=${process.env.REACT_APP_THUMBNAIL_CAT}`
            const imageUrl = `${e.CheminAbsolu__c}`

            images.push({
                "imageUrl" : imageUrl,
                "id" : id,
            })
        })

        const idArray:any = cardId.split(',');
        idArray.forEach((id) => {
            const exists = images.some((image) => image.id === id);
            if (!exists) {
                images.push({
                    "imageUrl" : "https://placehold.co/600x400?text=No+Image",
                    "id" : id,
                })
            }
        });
        return images
    } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
        }
    }
};
export { fetchImages, fetchThumbnails };