import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import BlockImage from "../components/blockImage";
import BlockImageWrapperd from "../components/blockImageWrapperd";
import BlockPartners from "../components/BlockPartners";
import BlockSteps from "../components/BlockSteps";
import CardsReassurance from "../components/cardsReassurance";
import Button from "../components/button";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function GuidPage() {
  return (
    <div className="page-guid">
      <Helmet>
        <title>Chasseurs d’Autos - Tout savoir sur le fonctionnement de Chasseurs d’Autos</title>
        <meta
            name="description"
            content="Découvrez comment fonctionne Chasseurs d’Autos et comment nous pouvons vous aider à acheter ou vendre votre véhicule en toute sécurité"
        />
        <link
            rel="canonical"
            href="https://chasseursdautos.com/qui-sommes-nous"
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>Comment ça marche ?</b>
        </Head>
        <Grid
          container
          sx={{
            px: {
              xs: 2,
            },
            my: {
              sm: 5,
            },
          }}
        >
          <Grid>
            <BlockImage guid>
              <h2>
                Vendez votre voiture à coup <br /> sûr avec Chasseurs d’autos !
              </h2>
              <div className="block-body">
                <p>
                  Envie d’être accompagné dans la vente de votre véhicule et
                  ainsi éviter les tracas avec les particuliers ? Confiez la
                  vente de votre véhicule à Chasseurs d’Autos, le premier réseau
                  d’indépendants adossé à de véritables professionnels de
                  l’automobile. Nos experts s’occupent de tout.
                </p>
                <br />
                <br />
                <p>
                  De l’inspection en passant par la prise de photos, la mise en
                  ligne et les négociations avec les acheteurs, votre chasseur
                  d’Auto s’occupe de tout jusqu’à la sécurisation de votre
                  paiement entre particuliers grâce à un mandat inédit dans la
                  profession !
                </p>
                <br />
                <br />
              </div>
              <div className="actions">
                {/* <Button
                  text="Des questions ? Voir les différentes étapes"
                  outlined
                /> */}
              </div>
            </BlockImage>
          </Grid>
        </Grid>
        {/* <BlockSteps /> */}
        <BlockImageWrapperd
          title="Un Mandat inédit & Attractif"
          subtitle="
            La sérénité a enfin un prix abordable
          "
        >
          {
            <div className="content">
              <p>
                Mise à jour à venir.
              </p>
            </div>
          }
          {/* <div className="content">
            <p>
              Le service Chasseurs d’Autos est proposé à partir de 990€, quel
              que soit le prix de vente de votre véhicule !
              <br />
              <br />
              Il comprend :
            </p>
            <ul>
              <img className="img-dk" src="/images/Mandat.png" alt="image" />
              <li>La prise de photos et la mise en ligne de votre annonce</li>
              <li>
                Plusieurs points de contrôle dans un centre partenaire (Essai
                routier + contrôles mécaniques)
              </li>
              <li>
                La gestion des contacts et prise en rendez-vous avec les
                acheteurs potentiels
              </li>
              <li>
                Une garantie de 6 mois en inclusion pour le futur acquéreur
              </li>
              <li>La sécurisation du transfert des fonds</li>
              <li>La proposition de financement et services complémentaires</li>
            </ul>
            <img className="img-mb" src="/images/Mandat.png" alt="image" />
          </div> */}
        </BlockImageWrapperd>
        <CardsReassurance />
        <BlockPartners />
      </PageWrapper>
    </div>
  );
}
