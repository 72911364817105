import React from "react";
import Grid from "./grid";

const network = [
  {
    region: "21 - COTE D'OR",
    agetns: [
      {
        fname: "FAUSSURIER",
        lname: "Yohan",
        city: "DIJON",
      },
      {
        fname: "GERARD",
        lname: "Hervé",
        city: "DIJON",
      },
    ],
  },
  {
    region: "25 - DOUBS",
    agetns: [
      {
        fname: "ABEL",
        lname: "Alexandre",
        city: "Besançon - PONTARLIER",
      },
    ],
  },
  {
    region: "29 - FINISTERE",
    agetns: [
      {
        fname: "DROUHIN",
        lname: "Christophe",
        city: "Quimper",
      },
      {
        fname: "BERY",
        lname: "Antoine",
        city: "Quimper",
      }
    ],
  },
  {
    region: "35 - ILE ET VILAINE",
    agetns: [
      {
        fname: "MALLE",
        lname: "Etienne",
        city: "Rennes",
      },
      {
        fname: "LEGER PROVOST",
        lname: "Jeremie",
        city: "Rennes",
      },
    ],
  },
  {
    region: "42 - LOIRE",
    agetns: [
      {
        fname: "BOZBIYIK",
        lname: "Aykut",
        city: "SAINT ETIENNE",
      },
    ],
  },
  {
    region: "44 - LOIRE ATLANTIQUE",
    agetns: [
      {
        fname: "DUTERTRE",
        lname: "Franck",
        city: "Nantes",
      },
    ],
  },
  {
    region: "45 - LOIRET",
    agetns: [
      {
        fname: "CARNEIRO",
        lname: "José",
        city: "GIEN - MONTARGIS",
      },
      {
        fname: "LAMANDE",
        lname: "Sébastien",
        city: "ORLÉANS - OLIVET",
      },
    ],
  },
  {
    region: "59 - NORD",
    agetns: [
      {
        fname: "VANSTEENE",
        lname: "Maxime",
        city: "LILLE",
      },
    ],
  },
  {
    region: "69 - RHONE",
    agetns: [
      {
        fname: "GOUSSI",
        lname: "Faycal",
        city: "LYON",
      },
    ],
  },
  {
    region: "71 - SAONE ET LOIRE",
    agetns: [
      {
        fname: "NEVEU",
        lname: "Bertrand",
        city: "MACON - BOURG EN BRESSE",
      },
      {
        fname: "GERARD",
        lname: "Hervé",
        city: "CHATENOY LE ROYAL",
      },
    ],
  },
  {
    region: "77 - SEINE ET MARNE",
    agetns: [
      {
        fname: "GAUVIN",
        lname: "Gael",
        city: "MEAUX",
      },
      {
        fname: "DE AZEVEDO",
        lname: "Wilson",
        city: "SENS - PROVINS",
      },
    ],
  },
  {
    region: "78 - YVELINES",
    agetns: [
      {
        fname: "CHATELAIN",
        lname: "Cyrille",
        city: "ACHERES",
      },
    ],
  },
  {
    region: "89 - YONNE",
    agetns: [
      {
        fname: "DE AZEVEDO",
        lname: "Wilson",
        city: "SENS - PROVINS",
      },
    ],
  },
  {
    region: "FRANCE",
    agetns: [
      {
        fname: "BOLLON",
        lname: "Cédric",
        city: "",
      },
    ],
  },


];

export default function Agents() {
  return (
    <div className="agents">
      <Grid container maxWidth="lg">
        <div className="wrapper">
          {network.map((n, i) => (
            <div className="cards" key={i}>
              <h5>{n.region}</h5>
              {n.agetns.map((agent, k) => (
                <div className="card" key={k}>
                  <div className="card-wrapper">
                    <div className="top">
                      <div className="name">
                        <p className="firstname">{agent.fname}</p>
                        <p className="lastname">{agent.lname}</p>
                      </div>
                    </div>
                    <div className="bottom">
                      <p className="city">{agent.city}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Grid>
    </div>
  );
}
