import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Agents from "../components/Agents";
import CardsReassurance from "../components/cardsReassurance";
import Grid from "../components/grid";
import BlockImage from "../components/blockImage"
import BlockImageWrapperd from "../components/blockImageWrapperd"
import { Helmet } from "react-helmet";

export default function GuidPage() {
  return (
    <div className="page-guid">
      <Helmet>
            <title>Chasseurs d’Autos - Découvrez l’équipe et la mission de Chasseurs d’Autos</title>
        <meta
          name="description"
                content="Chasseurs d’Autos est le premier réseau d’indépendants adossé à de véritables professionnels de l’automobile. Découvrez notre équipe et notre mission"
        />
        <link
          rel="canonical"
          href="https://chasseursdautos.com/qui-sommes-nous"
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>Qui sommes-nous ?</b>
        </Head>

        <Grid>
          <BlockImage about>
            <h2>Notre concept</h2>
            <div className="block-body">
              <p>Avec de nombreuses années d’expérience, <strong>Chasseurs d’Auto est un acteur principal de la vente et l’achat de véhicule d’occasion en France.</strong></p>
              <br/>
              <p>Choisir Chasseurs d’Autos vous permet de réaliser <strong>une transaction sereine, gérée de A à Z par des professionnels de l’Auto.</strong></p>
              <br/>
              <p>Un Chasseur s’occupera <strong>d’expertiser gratuitement</strong> votre véhicule pour réaliser une estimation au plus juste en fonction du marché actuel.</p>
              <br/>
              <p>Son estimation réalisée, votre Chasseur se chargera de réaliser un <strong>shooting photo de haute qualité</strong> grâce à notre application dédiée. <strong>Ces photos seront diffusées sur notre site chasseursdautos.com, Le Bon Coin, La Centrale et d’autres annonceurs automobile</strong> sans oublier les différents réseaux sociaux comme notre page <strong>Facebook et Instagram.</strong> Le but est donc de capter un maximum de personne en un minimum de temps !</p>
            </div>
          </BlockImage>

          <BlockImageWrapperd title="Notre accompagnement commercial" subtitle="">
            <div className="content">
              <div className="block-body">
                <p>Votre Chasseur s’occupera de <strong>gérer les appels, les visites, et de tenir un discours commercial mettant en avant les équipements et caractéristiques de votre véhicule.</strong> Il mettra à profit son expérience professionnelle pour conseiller le futur acquéreur de votre Auto.</p>
                <br/>
                <p>Grace à notre partenaire Financement, <strong>votre acheteur se verra proposer une solution financière adaptée à ses besoins.</strong> Un réel atout quand on sait que 70% des achats de véhicule sont financés !</p>
                <br/>
                <p>Chasseurs d’Autos s’occupera de finaliser la vente ! De la gestion administrative du dossier jusqu’au paiement sécurisé, <strong>votre Chasseur s’occupera de tout.</strong></p>
              </div>
            </div>
          </BlockImageWrapperd>
          

          <BlockImageWrapperd title="Nos services complémentaires" subtitle="">
            <div className="content">
              <div className="block-body">
                <p>Votre acheteur se verra proposé une solution pour <strong>garantir son nouveau partenaire à 4 roues.</strong> De 6 à 72 mois suivant le mode de financement choisi. Une réelle opportunité !!</p>
                <br/>
                <p>Cerise sur le gâteau, Chasseurs d’Autos vous propose également une <strong>recherche personnalisée de votre futur véhicule.</strong> Notre réseau de partenaires automobiles nous permettant de répondre à n’importe quelle demande.</p>
              </div>
            </div>
          </BlockImageWrapperd>

          <BlockImageWrapperd title="Pourquoi vendre ou acheter avec Chasseurs d’Autos ?" subtitle="Nos avantages">
            <div className="content">
              <div className="block-body">
                <ul>
                  <li>Meilleure valorisation de votre véhicule comparé à une reprise concession</li>
                  <li>Nos véhicules sont contrôlés</li>
                  <li>20 photos professionnelles avec diffusion massive sur les infomédiaires</li>
                  <li>Financement</li>
                  <li>Gestion administrative de votre dossier de vente</li>
                  <li>Possibilité de garantie</li>
                  <li>Paiement sécurisé</li>
                  <li>Reprise de votre ancien véhicule et rachat cash possible</li>
                  <li>Un service de conciergerie à votre disposition</li>
                </ul>
              </div>
            </div>
          </BlockImageWrapperd>
        </Grid>
        <Agents />
        <CardsReassurance />
      </PageWrapper>
    </div>
  );
}
