import React from "react";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1>
          <span>Vendez bien.</span>
          <span className="title-highlight">Achetez mieux.</span>
        </h1>
      </div>
    </div>
  );
};

export default Hero;
